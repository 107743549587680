
import { Options, Vue } from 'vue-class-component';
import { TargetSettingClient } from '@/services/Services';
import MultipleLineChart from '@/components/multipleLineChart.vue';
import TargetSettingModal from '../modals/targetSettingModal.vue';
import * as OM from '@/Model';
import { Watch } from 'vue-property-decorator';

@Options({
    components: { 
        MultipleLineChart
    },
})
export default class TargetSetting extends Vue {

    availableYearsForProjection: string[] = [];
    targetSettings: OM.TargetSetting[] = [];

    tonnesVisualization: boolean = false;
    multilineChartKgId: number = 0;
    multilineChartTonnesId: number = 0;

    created() {
        this.init();
    }

    init() {
        this.multilineChartKgId = Math.random();
        this.multilineChartTonnesId = Math.random();

        Promise.all([
            TargetSettingClient.getAvailableYearsForTarget(),
            TargetSettingClient.getAllTargetSettings()
        ])
        .then(xs => {
            this.availableYearsForProjection = xs[0];
            this.targetSettings = xs[1];
        })
    }

    @Watch('tonnesVisualization')
    updateIds() {
        if(this.tonnesVisualization)
            this.multilineChartTonnesId = Math.random();
        else
            this.multilineChartKgId = Math.random();
    }

    getConvertedEmissionsByYear(emissionsByYear: {[key: string]: number}) {
        var ris: { [key: string]: number; } = {};
        var keys = Object.keys(emissionsByYear);
        keys.forEach(element => {
            ris[element] = emissionsByYear[element] / 1000;
        });

        return ris;
    }

    newProjectionModal() {
        this.$opModal.show(TargetSettingModal, {
            availableYears: this.availableYearsForProjection,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
