
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient, TargetSettingClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class TargetSettingModal extends Vue {

    @Prop({
        default: () => []
    }) availableYears: string[];

    @Prop() callback: any;

    selectedYearString: string = "";
    model: OM.TargetSettingCreateVM = new OM.TargetSettingCreateVM();

    scope1KgCo2e: number = 0;
    scope2KgCo2e: number = 0;
    scope3KgCo2e: number = 0;

    created() {
        this.model.scope1ReductionPercentage = 0;
        this.model.scope2ReductionPercentage = 0;
        this.model.scope3ReductionPercentage = 0;
    }

    get disabled() {
        return !this.model.baseYear || !this.model.targetYear;
    }

    getScopesValues() {
        this.model.baseYear = parseInt(this.selectedYearString);
        Promise.all([
            ActivityDataRecorderClient.getScope1TotalEmissionsByYear(this.model.baseYear),
            ActivityDataRecorderClient.getScope2TotalEmissionsByYear(this.model.baseYear),
            ActivityDataRecorderClient.getScope3TotalEmissionsByYear(this.model.baseYear)
        ])
        .then(xs => {
            this.scope1KgCo2e = xs[0];
            this.scope2KgCo2e = xs[1];
            this.scope3KgCo2e = xs[2];
        })
    }

    createProjection() {
        TargetSettingClient.createNewTarget(this.model)
        .then(x => {
            this.callback();
        })
    }
    
}
